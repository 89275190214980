export enum types {
  registrationDate = 1,
  depositDate = 2,
}
export enum DrawingConsiderationPeriod {
  RENEWAL = 1,
  ANNUAL_FEE = 2,
}

export enum locale {
  En = "en",
  Ar = "ar",
}

export const localeName = (
  type: string
) => {
  switch (type) {
    case locale.En:
      return "en";
    case locale.Ar:
      return "ar";
    default:
      return "en";
  }
};

export const TypeDrawingConsiderationPeriod = (
  type: DrawingConsiderationPeriod
) => {
  switch (type) {
    case DrawingConsiderationPeriod.ANNUAL_FEE:
      return "annual_fee";
    case DrawingConsiderationPeriod.RENEWAL:
      return "renewal";
    default:
      return "annual_fee";
  }
};

export const TypeTrademarkRenewalonsiderationDate = (type: types) => {
  switch (type) {
    case types.depositDate:
      return "deposit_date";
    case types.registrationDate:
      return "registration_date";
    default:
      return "deposit_date";
  }
};
