import { useContext, useEffect, useState } from "react";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
// import UserContext from '../../../contexts/user/context'
import {
  ICountry,
  IUser,
} from "../../../../models/managent-collection/user/response";
import Space from "../../../general/antd/space";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import Table from "../../../general/table-components/table";
import { useNavigate } from "react-router-dom";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import CountryContext from "../../../../context/management-collection/country/context";
import { Tag } from "antd";
import { localeName } from "../../../../models/managent-collection/country/enum";

interface IProps {}

const CountryTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(CountryContext);

  const columns: ColumnProps<ICountry>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: {
        multiple: 4,
      },
    },
    {
      title: t("currency_name"),
      dataIndex: "currencyName",
      sorter: {
        multiple: 2,
      },
      key: "currencyName",
    },
    {
      title: t("currency_code"),
      dataIndex: "currencyCode",
      sorter: {
        multiple: 3,
      },
      key: "currencyCode",
    },
    {
      title: t("locale"),
      dataIndex: "locale",
      key: "locale",
      render: (val) => <Tag> {localeName(val)}</Tag>,
    },
    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCountry(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        size="small"
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default CountryTable;
