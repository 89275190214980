import { Button, Col, Popover, Row } from "antd";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import office from "../../../../assets/images/icon-files/office.jpg";
import a from "../../../../assets/images/icon-files/pdf.png";
import b from "../../../../assets/images/user.jpg";
import PatentsContext from "../../../../context/patents-collection/patents/context";
import PatentsContextProvider from "../../../../context/patents-collection/patents/provider";
import { IClient } from "../../../../models/client/response";
import { getDrawingCountryType } from "../../../../models/drawing-collection/drawing/enum";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import { IMedium } from "../../../../models/medium/response";
import { getPatentsType } from "../../../../models/patents-collection/patents/enum";
import { IUpdatePatents } from "../../../../models/patents-collection/patents/request";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import FileUploader from "../../../form-components/file-uploader";
import MainForm from "../../../form-components/main-form";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import styles from "./style.module.scss";
import { FaTrash } from "react-icons/fa";
import GeneralForm from "../../../form-components/general-form";

interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(PatentsContext);

  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState<any>();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [fileId, setfileId] = useState<IMedium>(null);
  const typesCountry = [1, 2];
  const types = [1, 2];

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);
  // get all CLients from api

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  const [typeFile, settypeFile] = useState(undefined);
  useEffect(() => {
    switch (details?.file?.extension ?? fileId?.extension) {
      case "pdf":
        settypeFile(a);
        break;
      case "docx":
        settypeFile(office);
        break;

      default:
        settypeFile(b);
        break;
    }
  }, [details?.name, fileId]);

  useEffect(() => {
    details && setfileId(details?.file);
  }, [details]);

  return (
    <GeneralForm
      values={[
        {
          key: "fileId",
          value: fileId?.id ?? null,
        },
      ]}
      onSubmit={async (data) => {
        details
          ? await actions.updatePatents(details?.id, data)
          : await actions.createPatents(data);
      }}
      title={
        details
          ? t("update_patents_and_utility_models")
          : t("add_patents_and_utility_models")
      }
      defaultValues={
        {
          name: getTranslations(details?.translations?.name) ?? null,
          clients: details?.clients?.map((client) => client.id),
          countryType: details?.countryType,
          description: details?.description,
          mainCountryId: details?.mainCountry?.id,
          type: details?.type,
          explanation: details?.explanation,
          fileId: fileId?.id ?? null,
          file2Id: fileId?.id ?? null,
        } as IUpdatePatents
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="type"
                label={`${t("pation_type")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: types.map((type, index) => {
                    return {
                      key: index,
                      label: t(getPatentsType(type) as string),
                      value: type as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryType"
                label={`${t("type_drawing")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: typesCountry.map((type, index) => {
                    return {
                      label: t(getDrawingCountryType(type) as string),
                      value: type as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mainCountryId"
                label={`${t("main_country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="clients"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "multiSelect",
                  loading: clientsLoading,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="explanation"
                label={`${t("explanation")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="description"
                label={`${t("description")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <>
              <Col span={24}>
                <div>
                  <FormItem label={t("file")}>
                    <Controller
                      name="fileId"
                      rules={{
                        required: {
                          value: false,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <FileUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              setfileId(response);
                              // field.onChange(response?.id);
                            }}
                            fileFor={MediumFor.COPYRIGHT_FILE}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </div>
              </Col>
              {fileId && (
                <Col span={5}>
                  <Popover
                    content={<div>{t("click_here_to_download_file")}</div>}
                  >
                    <a href={details?.file?.url}>
                      <div className={styles.fileCardContainer}>
                        <Image
                          src={typeFile}
                          className={styles?.imgFile}
                          alt={details?.file?.extension}
                        />
                      </div>
                    </a>
                  </Popover>
                  <FaTrash
                    onClick={() => setfileId(null)}
                    className={styles.trash}
                  />
                </Col>
              )}
            </>
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const PationsForm = () => {
  return (
    <PatentsContextProvider>
      <Form />
    </PatentsContextProvider>
  );
};

export default PationsForm;
