import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import UserContext from '../../../contexts/user/context'
import { Col, Row, Typography } from "antd";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import ClientContext from "../../../context/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { getBooleanTypes, getTypesColor } from "../../../models/client/enum";
import { IClient } from "../../../models/client/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import FormHookModal from "../../general/modals/form-hook-modal";
import ClientBtn from "../../general/table-components/actions/client-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";

const { Paragraph, Title } = Typography;

interface IProps {}

const ClientTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(ClientContext);
  const [clientSelected, setClinetSelected] = useState<IClient>();

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("copy")}
        loading={loading.includes("copy")}
        title={t("copy_is_copied")}
        hideModal={() => {
          hideFormodal();
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          // alert("ss");
          actions.copyClinet(clientSelected?.id);
          actions.getData();
        }}
        style={{
          marginTop: "15vh",
        }}
      >
        <Paragraph> {t(`${"is_copied_ready"}`)}: </Paragraph>
        <Row gutter={[16, 8]}>
          <Col span={8} offset={9}>
            <Title level={5}> {clientSelected?.name} </Title>
          </Col>
        </Row>
      </FormHookModal>
    ),
    [clientSelected, loading.includes("copy")]
  );

  const columns: ColumnProps<IClient>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 2,
      },
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("responsibl_person_name"),
      align: "center",
      dataIndex: "responsiblPersonName",
      key: "responsiblPersonName",
    },
    {
      title: t("type"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTypes(Number(record))}
            color={getTypesColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
      align: "center",
      key: "mobile",
    },
    {
      title: t("second_mobile"),
      dataIndex: "secondMobile",
      align: "center",
      key: "secondMobile",
    },
    {
      title: t("is_copied"),
      dataIndex: "is_copied",
      align: "center",
      key: "is_copied",
      render: (record) => {
        return (
          <Tag
            title={record == 0 ? t("not_copied") : t("copied")}
            color={record == 0 ? "orange" : ""}
          />
        );
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          {record.is_copied == 0 ? (
            <EditBtn
              onClick={async () => {
                navigate(`update/${record?.id}`);
              }}
            ></EditBtn>
          ) : (
            <EditBtn disabled></EditBtn>
          )}
          {record.is_copied == 0 ? (
            <ClientBtn
              onClick={async () => {
                setClinetSelected(record);
                showFormModal();
              }}
            />
          ) : (
            <ClientBtn disabled></ClientBtn>
          )}
          {record.is_copied == 0 ? (
            <DeleteBtn
              onConfirm={async () => {
                await actions.deleteClient(record?.id);
                eventManager.emit(EVENT_SUCCESS);
              }}
              loading={loading.includes("delete")}
            />
          ) : (
            <DeleteBtn
              onConfirm={async () => {
                await actions.deleteClient(record?.id);
                eventManager.emit(EVENT_SUCCESS);
              }}
              disabled
            ></DeleteBtn>
          )}
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        size="small"
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ClientTable;
