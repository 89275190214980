import { Row, Col, Divider, Select } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClientContext from "../../../context/client/context";
import { getBooleanTypes } from "../../../models/client/enum";
import { IUpdateClient } from "../../../models/client/request";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { MediumFor } from "../../../models/medium/enum";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import OldImage from "../../form-components/old-image";
import { IClient } from "../../../models/client/response";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import FileUploader from "../../form-components/file-uploader";
import office from "../../../assets/images/icon-files/office.jpg";
import a from "../../../assets/images/icon-files/pdf.png";
import { FaTrash } from "react-icons/fa";
import { getTranslations } from "../../../utils/helpers/translations";
import CommercialRecordContextProvider from "../../../context/commercial-records/provider";
import { ICountry } from "../../../models/managent-collection/user/response";
import { ICommercialRecordsDetails } from "../../../models/commercial-records/response";
import { IUpdateCommercialRecords } from "../../../models/commercial-records/request";
import CommercialRecordContext from "../../../context/commercial-records/context";
const Form = () => {
  const [file, setFile] = useState(undefined);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [details, setDetails] = useState<ICommercialRecordsDetails>();
  const [detailsLoading, setDetailsLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation();
  const { actions } = useContext(CommercialRecordContext);

  useEffect(() => {
    const getClientsLight = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClientsLight();
  }, []);

  useEffect(() => {
    const getCountriesLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountriesLight();
  }, []);

  // get Details
  useEffect(() => {
    const getDetails = async () => {
      try {
        id &&
          (await EndPoints.commercialRecord
            .getCommercialRecord(Number(id))
            .then((res) => {
              setDetails(res.data);
              res.data.file && setFile(res?.data?.file?.url);
            }));
      } catch (error) {
      } finally {
        setDetailsLoading(false);
      }
    };
    getDetails();
  }, []);

  const [typeFile, settypeFile] = useState(undefined);

  useEffect(() => {
    switch (details?.file?.extension) {
      case "pdf":
        settypeFile(a);
        break;
      case "docx":
        settypeFile(office);
        break;
      default:
        settypeFile(file);
        break;
    }
  }, [details?.number]);

  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateCommercialRecord(details?.id, data)
          : actions.createCommercialRecord(data);
      }}
      title={
        details ? t("update_commercial_record") : t("add_commercial_record")
      }
      defaultValues={
        {
          ...details,
          clientId: details?.client?.id,
          fileId: details?.file?.id ?? null,
          countryId: details?.country?.id,
        } as IUpdateCommercialRecords
      }
    >
      {detailsLoading ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="number"
                label={`${t("number")}`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="purpose"
                label={`${t("purpose")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mainCenter"
                label={`${t("main_center")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  loading: clientsLoading,
                  allowSearch: true,
                  type: "select",
                  options: clients.map((client, index) => {
                    return {
                      label: client?.name,
                      value: client?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  loading: countriesLoading,
                  type: "select",
                  allowSearch: true,
                  options: countries.map((country, index) => {
                    return {
                      label: country?.name,
                      value: country?.id,
                    };
                  }),
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <div>
                <FormItem label={t("file")}>
                  <Controller
                    name="fileId"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          showUploadList
                          onChange={(response) => {
                            setFile(undefined);
                            field.onChange(response?.id);
                          }}
                          fileFor={MediumFor.CLIENT_COMMERCIAL}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
              {file && (
                <Col span={5}>
                  <OldImage src={typeFile} />
                  <FaTrash
                    className={styles.trash}
                    onClick={() => setFile(null)}
                  />
                </Col>
              )}
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};
const CommercialRecordForm = () => {
  return (
    <CommercialRecordContextProvider>
      <Form />
    </CommercialRecordContextProvider>
  );
};
export default CommercialRecordForm;
