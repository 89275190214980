import {
  AppstoreOutlined,
  HomeOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiFlag, FiLayers } from "react-icons/fi";
import Spin from "../../components/general/antd/spin";
import PageHeader from "../../components/general/layout/page-header";
import Card from "../../components/home/cards";
import { IStatistics } from "../../models/statistcs/respons";
import EndPoints from "../../services/end-points";
import Chart from "../../components/general/charts/pie";
interface IProps {}

const Home: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<IStatistics>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getAllStatistics = async () => {
      try {
        const a = await EndPoints.statistics.getAllStatistics({});
        setStatistics(a?.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getAllStatistics();
  }, []);

  return (
    <>
      <PageHeader title={t("home_title")} subTitle={t("home_sub_title")} />
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row gutter={[28, 28]}>
            <Col lg={12} xs={24}>
              <Chart
                lables={[t("count_open_drawing_request"),t("industrial_models"),t("count_open_trademark_request"),t("trademarks"),t("users")]}
                count={[
                  statistics?.DrawingRequestOpenCount,
                  statistics?.DrawingsCount,
                  statistics?.TrademarkRequestOpenCount,
                  statistics?.TrademarksCount,
                  statistics?.UsersCount,
                ]}
              />
            </Col>
            <Col lg={12} xs={24}>
              <Chart
                lables={[t("companies"), t("persons"), t("countries")]}
                count={[
                  statistics.ClientsCorporateCount,
                  statistics?.ClientsIndividualCount,
                  statistics?.CountriesCount,
                ]}
              />
            </Col>
          </Row>
          <Divider orientation="center">{t("statistics")}</Divider>
          <Row gutter={[8, 8]}>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="persons"
                title="clients"
                subTitle="persons"
                icon={<UsergroupAddOutlined />}
                count={statistics?.ClientsIndividualCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="companies"
                title="clients"
                subTitle="companies"
                icon={<HomeOutlined />}
                count={statistics?.ClientsCorporateCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="users"
                title="users"
                icon={<UsergroupAddOutlined />}
                count={statistics?.UsersCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="trademarks"
                title="trademarks"
                icon={<TagsOutlined />}
                count={statistics?.TrademarksCount}
              />
            </Col>
            <Col xl={8} lg={12}  xs={24}>
              <Card
                toolTip="count_open_trademark_request"
                title="count_open_trademark_request"
                icon={<FiLayers />}
                count={statistics?.TrademarkRequestOpenCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                title="industrial_models"
                toolTip="industrial_models_toltip"
                icon={<AppstoreOutlined />}
                count={statistics?.DrawingsCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="count_open_drawing_request"
                title="count_open_drawing_request"
                icon={<FiLayers />}
                count={statistics?.DrawingRequestOpenCount}
              />
            </Col>
            <Col xl={8} lg={12} sm={12} xs={24}>
              <Card
                toolTip="countries"
                title="countries"
                icon={<FiFlag />}
                count={statistics?.CountriesCount}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Home;
