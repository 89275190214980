import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DefoultImage from "../../../../assets/images/add-image.png";
import DrawingContext from "../../../../context/drawing-collection/drawing/context";
import DrawingContextProvider from "../../../../context/drawing-collection/drawing/provider";
import { IClient } from "../../../../models/client/response";
import {
  getDrawingCountryType,
  getDrawingType,
} from "../../../../models/drawing-collection/drawing/enum";
import { IUpdateDrawing } from "../../../../models/drawing-collection/drawing/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import { IMedium } from "../../../../models/medium/response";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import ImageUploader from "../../../form-components/image-uploader";
import MainForm from "../../../form-components/main-form";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import styles from "./style.module.scss";
import GeneralForm from "../../../form-components/general-form";

interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(DrawingContext);
  const imgUrlRef = useRef(null);

  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState<any>();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [images, setImages] = useState<IMedium[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const typesCountry = [1, 2];
  const types = [1, 2];

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // useEffect(() => {
  //   id && actions.getDetails(Number(id));
  // }, []);

  useEffect(() => {
    setImgUrl(details?.logo?.url ?? DefoultImage);
    details ? setImages(details?.photos) : setImages([]);
    details ? setIds(details?.photos?.map((photo) => photo.id)) : setIds([]);
  }, [details]);

  const handleDelelte = (id: number) => {
    const newArrayIds = ids.filter((f) => f !== id);
    setIds(newArrayIds);
    const newArrayImages = images.filter((img) => img.id !== id);
    setImages(newArrayImages);
  };

  // get all Countries from api

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);
  // get all CLients from api

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  return (
    <GeneralForm
      onSubmit={async (data) => {
        details
          ? await actions.updateDrawing(details?.id, data)
          : await actions.createDrawing(data);
      }}
      values={[
        {
          key: "photos",
          value: ids,
        },
      ]}
      title={
        details
          ? t("update_industrial_models_and_drawings")
          : t("add_industrial_models_and_drawings")
      }
      defaultValues={
        {
          name: getTranslations(details?.translations?.name) ?? null,
          clients: details?.clients?.map((client) => client.id),
          countryType: details?.countryType,
          description: details?.description,
          logoId: details?.logo?.id,
          mainCountryId: details?.mainCountry?.id,
          note: details?.note,
          photos: ids,
          type: details?.type,
          materials: details?.materials,
        } as IUpdateDrawing
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={3} className={styles.colImg}>
              <FormItem>
                <Controller
                  name="logoId"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="BUTTON"
                        onChange={async (response) => {
                          imgUrlRef.current = response?.url ?? DefoultImage;

                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.DRAWING_ICON}
                      >
                        <Image
                          className={styles.img}
                          src={imgUrlRef.current ?? imgUrl}
                        />
                      </ImageUploader>
                    );
                  }}
                />
              </FormItem>
            </Col>

            <Col span={19}>
              <FieldBuilder
                name="description"
                label={`${t(
                  "brief_description_of_the_drawing_and_industrial_model"
                )}`}
                input={{ type: "text-area" }}
              />
            </Col>
            <Col span={24}>
              <Divider style={{ margin: "10px 0" }} />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="clients"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "multiSelect",
                  loading: clientsLoading,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="type"
                label={`${t("drawing_type")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: types.map((type, index) => {
                    return {
                      label: t(getDrawingType(type) as string),
                      value: type as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryType"
                label={`${t("type_drawing")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: typesCountry.map((type, index) => {
                    return {
                      label: t(getDrawingCountryType(type) as string),
                      value: type as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mainCountryId"
                label={`${t("main_country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="materials"
                label={`${t("materials_drawing")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>

            {details && (
              <>
                <Col span={24}>
                  <div>
                    <FormItem>
                      <Controller
                        name="a"
                        rules={{
                          required: {
                            value: false,
                            message: `${t("field_is_required_message")}`,
                          },
                        }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <ImageUploader
                              {...field}
                              type="DRAGGER"
                              multiple
                              onChange={(response) => {
                                // field.onChange(response?.id);
                                setImages([...images, response]);
                                setIds([...ids, response?.id]);
                              }}
                              imageFor={MediumFor.DRAWING_PHOTOS}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    {images?.map((a) => {
                      return (
                        <Col
                          xxl={2}
                          xl={3}
                          lg={4}
                          md={6}
                          sm={7}
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            height={100}
                            preview
                            width="100%"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              objectFit: "contain",
                            }}
                            src={a.url}
                          />

                          <Button
                            onClick={() => handleDelelte(a.id)}
                            className={styles.delete}
                            type="primary"
                            shape="circle"
                            icon={<DeleteFilled className={styles.icon} />}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const DrawingForm = () => {
  return (
    <DrawingContextProvider>
      <Form />
    </DrawingContextProvider>
  );
};

export default DrawingForm;
