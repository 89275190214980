import { Col, Divider, Row, Tabs, TabsProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../../context/app/context";
import CountryContext from "../../../../context/management-collection/country/context";
import CountryContextProvider from "../../../../context/management-collection/country/provider";
import {
  localeName,
  TypeDrawingConsiderationPeriod,
  TypeTrademarkRenewalonsiderationDate,
} from "../../../../models/managent-collection/country/enum";
import { IUpdateCountry } from "../../../../models/managent-collection/country/request";
import { IRole } from "../../../../models/managent-collection/role/response";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";
import styles from "./style.module.scss";
const Form = () => {
  const { t, i18n } = useTranslation();
  const { details, actions, loading } = useContext(CountryContext);
  const { direction } = useContext(AppContext);

  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);

  const { id } = useParams();
  const types = [1, 2];
  const locale = ["en", "ar"];
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("trade_marks"),
      children: (
        <>
          <Row gutter={[16, 8]}>
            <Col span={6}>
              <div>
                <FieldBuilder
                  name="trademarkRenewalConsiderationDate"
                  label={`${t("base_date_type")}`}
                  rules={{ required: true }}
                  width="large"
                  input={{
                    type: "select",
                    options: types?.map((type, index) => {
                      return {
                        key: index,
                        label: t(TypeTrademarkRenewalonsiderationDate(type)),
                        value: type,
                      };
                    }),
                  }}
                />
              </div>
            </Col>

            {/* <Col span={8}></Col> */}
            <Col span={6}>
              <FieldBuilder
                rules={{ required: true }}
                name="trademarkRegistrationProtectionPeriod"
                label={`${t("registration_protection_period")} ${t("years")}`}
                input={{ type: "number" }}
              />
            </Col>

            <Col span={6}>
              <FieldBuilder
                rules={{ required: true }}
                name="trademarkRenewalProtectionPeriod"
                label={`${t("renewal_protection_period")} ${t("years")}`}
                input={{ type: "number" }}
              />
            </Col>

            <Col span={6}>
              <FieldBuilder
                rules={{ required: true }}
                name="trademarkRenewalPeriod"
                label={`${t("basic_renewal_period")} ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Divider style={{ margin: "0" }} />
            <Col span={8}>
              <FieldBuilder
                label={`${t("up_to_end_of_month_in_basic_period")}`}
                name="completionLastMonthRenewalPeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Divider style={{ margin: "0" }} />
            <Col span={24}>
              <FieldBuilder
                label={`${t("thereRenewalWithFinePeriod")}`}
                name="thereRenewalWithFinePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="trademarkRenewalWithFinePeriod"
                label={`${t("additional_allow_period")}  ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>

            <Col span={8} style={{ marginTop: "1.6rem" }}>
              <FieldBuilder
                label={`${t("up_to_end_of_month_in_additional_period")}`}
                name="completionLastMonthRenewalWithFinePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Col span={8} style={{ marginTop: "1.6rem" }}>
              <FieldBuilder
                label={`${t("there_is_a_fine_in_the_additional_period")}`}
                name="thereFineForRenewalWithFinePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Divider style={{ margin: "0" }} />

            <Col span={11}>
              <Row gutter={8}>
                <Col span={24}>
                  <FieldBuilder
                    label={`${t("passive_protection")}`}
                    name="thereNegativePeriod"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={24}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="trademarkNegativeProtectionPeriod"
                    label={`${t("passive_protection_period")} ${t("months")}`}
                    input={{ type: "number" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider
                type="vertical"
                style={{ height: "7rem", marginTop: ".8rem" }}
              />
            </Col>

            <Col span={11}>
              <Row gutter={8}>
                <Col span={24}>
                  <FieldBuilder
                    label={`${t("trade_mark_multiple_reservation_in_request")}`}
                    name="trademarkMultipleReservationInRequest"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={24}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="trademarkMaxReservationCountInRequest"
                    label={`${t("max_reservations_in_request")}`}
                    input={{ type: "number" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: t("industrial_designs"),
      children: (
        <div>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <div>
                <FieldBuilder
                  name="drawingConsiderationDate"
                  label={`${t("base_date_type")}`}
                  rules={{ required: true }}
                  width="large"
                  input={{
                    type: "select",
                    options: types?.map((type, index) => {
                      return {
                        key: index,
                        label: t(TypeTrademarkRenewalonsiderationDate(type)),
                        value: type,
                      };
                    }),
                  }}
                />
              </div>
            </Col>
            <Col span={8}>
              <div>
                <FieldBuilder
                  name="drawingConsiderationPeriod"
                  label={`${t("drawingConsiderationPeriod")}`}
                  rules={{ required: true }}
                  width="large"
                  input={{
                    type: "select",
                    options: types?.map((type, index) => {
                      return {
                        key: index,
                        label: t(TypeDrawingConsiderationPeriod(type)),
                        value: type,
                      };
                    }),
                  }}
                />
              </div>
            </Col>

            <Col span={8}>
              <FieldBuilder
                rules={{ required: true }}
                name="drawingProtectionPeriod"
                label={`${t("drawingProtectionPeriod")} ${t("years")}`}
                input={{ type: "number" }}
              />
            </Col>

            <Divider style={{ margin: "0" }} />
            <Col span={12}>
              <FieldBuilder
                rules={{ required: true }}
                name="drawingRenewalPeriod"
                label={`${t("drawingRenewalPeriod")} ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                rules={{ required: false }}
                name="drawingAdditionalRenewalPeriod"
                label={`${t("drawingAdditionalRenewalPeriod")}  ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("drawingCompletionLastMonthRenewalPeriod")}`}
                name="drawingCompletionLastMonthRenewalPeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            {/* <Divider style={{ margin: "0" }} /> */}
            <Col span={8}>
              <FieldBuilder
                label={`${t("drawingThereAdditionalRenewalPeriod")}`}
                name="drawingThereAdditionalRenewalPeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                label={`${t(
                  "drawingCompletionLastMonthAdditionalRenewalPeriod"
                )}`}
                name="drawingCompletionLastMonthAdditionalRenewalPeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                label={`${t("drawingThereFineForAdditionalRenewalPeriod")}`}
                name="drawingThereFineForAdditionalRenewalPeriod"
                input={{ type: "checkBox" }}
              />
            </Col>

            <Divider style={{ margin: "0" }} />

            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <FieldBuilder
                    label={`${t("drawingMultipleReservationInRequest")}`}
                    name="drawingMultipleReservationInRequest"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="drawingMaxReservationCountInRequest"
                    label={`${t("drawingMaxReservationCountInRequest")}`}
                    input={{ type: "number" }}
                  />
                </Col>
                {/* <Col span={12}></Col> */}
                <Col span={12}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="drawingMaxRenewalCount"
                    label={`${t("drawingMaxRenewalCount")}`}
                    input={{ type: "number" }}
                  />
                </Col>
              </Row>
            </Col>

            {/* .................................. */}
            <Divider plain orientation="center" className={styles.textDivider}>
              {t("annual_fee")}
            </Divider>
            <Col span={12}>
              <FieldBuilder
                label={`${t("drawingCompletionLastMonthAnnualFeePeriod")}`}
                name="drawingCompletionLastMonthAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t(
                  "drawingCompletionLastMonthAdditionalAnnualFeePeriod"
                )}`}
                name="drawingCompletionLastMonthAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t("drawingThereFineForAdditionalAnnualFeePeriod")}`}
                name="drawingThereFineForAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t("drawingThereAdditionalAnnualFeePeriod")}`}
                name="drawingThereAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="drawingProtectionAnnualFeePeriod"
                label={`${t("drawingProtectionAnnualFeePeriod")}  ${t(
                  "months"
                )}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="drawingAdditionalAnnualFeePeriod"
                label={`${t("drawingAdditionalAnnualFeePeriod")}  ${t(
                  "months"
                )}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="drawingAnnualFeePeriod"
                label={`${t("drawingAnnualFeePeriod")}  ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: "3",
      label: t("patents_and_utility_models"),
      children: (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <div>
                <FieldBuilder
                  name="patentConsiderationDate"
                  label={`${t("base_date_type")}`}
                  rules={{ required: true }}
                  width="large"
                  input={{
                    type: "select",
                    options: types?.map((type, index) => {
                      return {
                        key: index,
                        label: t(TypeTrademarkRenewalonsiderationDate(type)),
                        value: type,
                      };
                    }),
                  }}
                />
              </div>
            </Col>

            <Col span={8}>
              <FieldBuilder
                rules={{ required: true }}
                name="patentProtectionPeriod"
                label={`${t("patentProtectionPeriod")} ${t("years")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: true }}
                name="utilityModelProtectionPeriod"
                label={`${t("utilityModelProtectionPeriod")} ${t("years")}`}
                input={{ type: "number" }}
              />
            </Col>

            <Divider plain orientation="center" className={styles.textDivider}>
              {t("annual_fee")}
            </Divider>
            <Col span={12}>
              <FieldBuilder
                label={`${t("patentCompletionLastMonthAnnualFeePeriod")}`}
                name="patentCompletionLastMonthAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t(
                  "patentCompletionLastMonthAdditionalAnnualFeePeriod"
                )}`}
                name="patentCompletionLastMonthAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t("patentThereFineForAdditionalAnnualFeePeriod")}`}
                name="patentThereFineForAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                label={`${t("patentThereAdditionalAnnualFeePeriod")}`}
                name="patentThereAdditionalAnnualFeePeriod"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="patentProtectionAnnualFeePeriod"
                label={`${t("patentAnnualFeePeriod")}  ${t("months")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="patentAdditionalAnnualFeePeriod"
                label={`${t("patentAdditionalAnnualFeePeriod")}  ${t(
                  "months"
                )}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="patentAnnualFeePeriod"
                label={`${t("patentProtectionAnnualFeePeriod")}  ${t(
                  "months"
                )}`}
                input={{ type: "number" }}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  // get details depends On id (getting from url)
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all Role from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({});
        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, []);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateCountry(details?.id, data)
          : await actions.createCountry(data);
      }}
      title={details ? t("update_country") : t("create_country")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          currencyName: getTranslations(details?.translations?.currencyName),
          locale:details?.locale,
          currencyCode: details?.currencyCode,
          agents: details?.agents,
          trademarkMultipleReservationInRequest:
            details?.trademarkMultipleReservationInRequest ?? false,
          completionLastMonthRenewalPeriod:
            details?.completionLastMonthRenewalPeriod ?? false,
          completionLastMonthRenewalWithFinePeriod:
            details?.completionLastMonthRenewalWithFinePeriod ?? false,
          thereFineForRenewalWithFinePeriod:
            details?.thereFineForRenewalWithFinePeriod ?? false,
          thereRenewalWithFinePeriod:
            details?.thereRenewalWithFinePeriod ?? false,
          thereNegativePeriod: details?.thereNegativePeriod ?? false,
          trademarkMaxReservationCountInRequest:
            details?.trademarkMaxReservationCountNnRequest,
          trademarkRenewalProtectionPeriod:
            details?.trademarkRenewalProtectionPeriod,
          trademarkRegistrationProtectionPeriod:
            details?.trademarkRegistrationProtectionPeriod,
          trademarkRenewalPeriod: details?.trademarkRenewalPeriod,
          trademarkRenewalConsiderationDate:
            details?.trademarkRenewalConsiderationDate,
          trademarkRenewalWithFinePeriod:
            details?.trademarkRenewalWithFinePeriod,
          trademarkNegativeProtectionPeriod:
            details?.trademarkNegativeProtectionPeriod,
          // drawing
          drawingAdditionalAnnualFeePeriod:
            details?.drawingAdditionalAnnualFeePeriod,
          drawingAdditionalRenewalPeriod:
            details?.drawingAdditionalRenewalPeriod,
          drawingAnnualFeePeriod: details?.drawingAnnualFeePeriod,
          drawingCompletionLastMonthAdditionalAnnualFeePeriod:
            details?.drawingCompletionLastMonthAdditionalAnnualFeePeriod ??
            false,
          drawingCompletionLastMonthAdditionalRenewalPeriod:
            details?.drawingCompletionLastMonthAdditionalRenewalPeriod ?? false,
          drawingCompletionLastMonthAnnualFeePeriod:
            details?.drawingCompletionLastMonthAnnualFeePeriod ?? false,
          drawingCompletionLastMonthRenewalPeriod:
            details?.drawingCompletionLastMonthRenewalPeriod ?? false,
          drawingConsiderationDate: details?.drawingConsiderationDate,
          drawingConsiderationPeriod: details?.drawingConsiderationPeriod,
          drawingMaxRenewalCount: details?.drawingMaxRenewalCount,
          drawingMaxReservationCountInRequest:
            details?.drawingMaxReservationCountInRequest,
          drawingMultipleReservationInRequest:
            details?.drawingMultipleReservationInRequest ?? false,
          drawingProtectionPeriod: details?.drawingProtectionPeriod,
          drawingRenewalPeriod: details?.drawingRenewalPeriod,
          drawingThereAdditionalAnnualFeePeriod:
            details?.drawingThereAdditionalAnnualFeePeriod ?? false,
          drawingThereAdditionalRenewalPeriod:
            details?.drawingThereAdditionalRenewalPeriod ?? false,
          drawingThereFineForAdditionalAnnualFeePeriod:
            details?.drawingThereFineForAdditionalAnnualFeePeriod ?? false,
          drawingThereFineForAdditionalRenewalPeriod:
            details?.drawingThereFineForAdditionalRenewalPeriod ?? false,
          drawingProtectionAnnualFeePeriod:
            details?.drawingProtectionAnnualFeePeriod,
          roles: details?.roles?.map((role) => role.id),

          // ........................patents

          patentConsiderationDate: details?.patentConsiderationDate,
          patentProtectionPeriod: details?.patentProtectionPeriod,
          utilityModelProtectionPeriod: details?.utilityModelProtectionPeriod,
          patentProtectionAnnualFeePeriod:
            details?.patentProtectionAnnualFeePeriod,
          patentAnnualFeePeriod: details?.patentAnnualFeePeriod,
          patentAdditionalAnnualFeePeriod:
            details?.patentAdditionalAnnualFeePeriod,
          patentCompletionLastMonthAnnualFeePeriod:
            details?.patentCompletionLastMonthAnnualFeePeriod ?? false,
          patentCompletionLastMonthAdditionalAnnualFeePeriod:
            details?.patentCompletionLastMonthAdditionalAnnualFeePeriod ??
            false,
          patentThereFineForAdditionalAnnualFeePeriod:
            details?.patentThereFineForAdditionalAnnualFeePeriod ?? false,
          patentThereAdditionalAnnualFeePeriod:
            details?.patentThereAdditionalAnnualFeePeriod ?? false,
        } as IUpdateCountry
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <Row gutter={[16, 8]}>
          {languages?.map((lang, index) => {
            return (
              <Col span={8}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t("name")} (${lang?.name})`}
                  input={{ type: "text" }}
                  rules={{ required: true }}
                />
              </Col>
            );
          })}
          <Col span={8}>
            <FieldBuilder
              rules={{ required: true }}
              name="currencyCode"
              label={`${t("currency_code")}`}
              input={{ type: "text" }}
            />
          </Col>
          {languages?.map((lang, index) => {
            return (
              <Col span={6}>
                <FieldBuilder
                  key={index}
                  name={`currencyName[${lang?.code}]`}
                  label={`${t("currency_name")} (${lang?.name})`}
                  input={{ type: "text" }}
                  rules={{ required: true }}
                />
              </Col>
            );
          })}
          <Col span={6}>
            <FieldBuilder
              name="locale"
              label={`${t("locale")}`}
              rules={{ required: false }}
              width="large"
              input={{
                type: "select",
                options: locale?.map((type, index) => {
                  return {
                    key: index,
                    label: t(localeName(type)),
                    value: type,
                  };
                }),
              }}
            />
          </Col>
          <Col span={6}>
            <FieldBuilder
              rules={{ required: true }}
              name="agents"
              label={`${t("agents")}`}
              input={{ type: "text" }}
            />
          </Col>
          <Col span={6}>
            <div>
              <FieldBuilder
                name="roles"
                label={`${t("genaral_follow_up _role")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "multiSelect",
                  loading: rolesLoading,
                  options: roles?.map((role, index) => {
                    return {
                      key: index,
                      label: role.name,
                      value: role.id,
                    };
                  }),
                }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Tabs items={items} />
          </Col>
        </Row>
      )}
    </MainForm>
  );
};

const ContryForm = () => {
  return (
    <CountryContextProvider>
      <Form />
    </CountryContextProvider>
  );
};
export default ContryForm;
